import React, { Component } from "react"
import { graphql, Link } from 'gatsby'
import Plx from "react-plx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

// Redux
import { invertHeader, resetHeader } from "../../actions"
import { connect } from "react-redux"

import { formatedDate } from "../../utils/date"
import { contrast } from "../../utils/contrast"

import WorkRelated from "../work/WorkRelated"

import Sharer from "../../components/Sharer"

class QaSingle extends Component {
  constructor(props) {
    super(props)
    this.data = props.data.qa
    this.related = props.data.qa.office ? props.data.qa.office.work : null
  }

  componentWillMount() {
    let headerContrast = contrast(
      this.props.data.qa.headerColor
        ? this.props.data.qa.headerColor
        : `#000000`
    )

    // invert top header
    if (headerContrast == `dark`) {
      this.props.invertHeader()
    }

    // store value in state
    this.setState({
      headerContrast: headerContrast,
    })
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.props.resetHeader()
  }

  headerClassName() {
    return this.state.headerContrast == `dark` ? `is-dark` : `is-light`
  }

  headerBackgroundStyle() {
    return {
      backgroundColor: this.data.headerColor,
    }
  }

  render() {
    let { title, office, createdAt, headerImage, content } = this.data

    const parallaxDataOpacity = [
      {
        start: 0,
        end: 750,
        easing: `easeInOutQuad`,
        properties: [
          {
            startValue: 1,
            endValue: 0,
            property: `opacity`,
          },
        ],
      },
    ]

    const parallaxHeader = [
      {
        start: 0,
        end: 1000,
        easing: `easeInOutCubic`,
        properties: [
          {
            startValue: 0,
            endValue: -300,
            property: `translateY`,
          },
          {
            startValue: 10,
            endValue: 10,
            property: `zIndex`,
          },
        ],
      },
    ]

    const parallaxContent = [
      {
        start: 0,
        end: 2500,
        easing: `easeInOutCubic`,
        properties: [
          {
            startValue: -80,
            endValue: 0,
            property: `translateY`,
          },
        ],
      },
    ]

    const parallaxDataHeader = [
      {
        start: 0,
        end: 600,
        easing: `easeInOutCubic`,
        properties: [
          {
            startValue: 0,
            endValue: 120,
            property: `translateY`,
          },
          {
            startValue: 1,
            endValue: 0,
            property: `opacity`,
          },
        ],
      },
    ]

    return (
      <Layout>
        <SEO title={title} />
      <section className="qa__single">

        <Plx parallaxData={parallaxHeader}>
          <header
            className={`qa__single__header ${this.headerClassName()}`}
            style={this.headerBackgroundStyle()}
          >
            {/* section header */}
            <hgroup className="single__header">
              <Plx parallaxData={parallaxDataOpacity}>
                <p className="h4 text-center">
                  <Link to="/culture/q&a/">Culture</Link>
                </p>
              </Plx>
            </hgroup>

            <hgroup className="qa__single__meta col-10 col-lg-8 mx-auto is-text-center">
              <Plx parallaxData={parallaxDataHeader}>
                <h1 className="h2">{title}</h1>
                <p className="h5">
                  {office && <span>{office.shortName} - </span>}
                  {formatedDate(createdAt)}
                </p>
              </Plx>
            </hgroup>

            <figure className="qa__single__header_image is-text-center">
              {headerImage && (
                <GatsbyImage image={getImage(headerImage)} alt={title} />
              )}
            </figure>
          </header>
        </Plx>

        <footer className="qa__single__content">
          <div className="container">
            <div className="row">
              <Plx parallaxData={parallaxContent}>
                {content && (
                  <div
                    className="col-12 col-md-10 col-lg-8 mx-auto entry"
                    dangerouslySetInnerHTML={{
                      __html: content.childMarkdownRemark.html,
                    }}
                  />
                )}
              </Plx>
            </div>
          </div>

          <Sharer />

          {this.related && <WorkRelated allWork={this.related} max={2} />}
        </footer>
      </section>
      </Layout>
    )
  }
}

export default connect(
  null,
  {
    invertHeader,
    resetHeader,
  }
)(QaSingle)

//
// Each work fields
//
export const query = graphql`
  query QaQuery($slug: String!) {
    qa: contentfulQa(slug: { eq: $slug }) {
      ...qaFragment
    }
  }
`
