import React, { Component } from "react"
import PropTypes from "prop-types"
import LinkTo from "gatsby-link"

import _ from "lodash"

// import Work from './Work'
import Arrow from "../../svg/Arrow"
import ContentPreview, {
  ContentPreviewLayouts,
} from "../preview/ContentPreview"
import indexColumns from "../../utils/indexColumns"

class WorkRelated extends Component {
  constructor(props) {
    super(props)
    // create class instance for column number, delay, etc
    this.indexColumns = new indexColumns(0)
  }

  layout() {
    let className = []

    // odd columns
    if (this.indexColumns.columnNumber % 2 > 0) {
      className.push(`col-12 col-md-6 no-padding__left-md`)
      className.push(`right-gutter`)
      className.push(ContentPreviewLayouts.compact_fill)
      // even columns
    } else if (this.indexColumns.columnNumber % 2 == 0) {
      className.push(`col-12 col-md-6 no-padding__right-md`)
      className.push(`left-gutter`)
      className.push(ContentPreviewLayouts.compact)
    }

    return className.join(` `)
  }

  list() {
    if (this.props.allWork) {
      // array for content
      let content = []
      let work = []

      // XXX: fixing hot reloader issue
      this.indexColumns.reset()

      if (this.props.randomize) {
        work = _.shuffle(this.props.allWork)
      } else {
        work = this.props.allWork
      }

      // loop all work
      work.map((work, index) => {
        // excluding parent slug, stop when maximum is reached
        if (
          work.slug !== this.props.slugToExclude &&
          this.indexColumns.columnNumber < this.props.max
        ) {
          // increment
          this.indexColumns.increment()

          // push each work
          content.push(
            <ContentPreview
              key={index}
              index={index}
              contentData={work}
              type="work"
              layout={this.layout()}
              visible={true}
              bottom={true}
              delay={this.indexColumns.getDelay()}
            />
          )
        }
      })

      return content
    } else {
      return []
    }
  }

  render() {
    return (
      <footer className="container-fluid content_related">
        <header className="row mb-2">
          <h3 className="col-auto no-padding-md">{this.props.label}</h3>
          <aside className="col-auto ml-auto no-padding-md">
            <LinkTo to={this.props.link} className="btn__arrow">
              <span className="h4">{this.props.linkLabel}</span>
              <Arrow />
            </LinkTo>
          </aside>
        </header>
        <div className="row">{this.list()}</div>
      </footer>
    )
  }
}

WorkRelated.defaultProps = {
  label: `Related Projects`,
  linkLabel: `All work`,
  link: `/work/`,
  randomize: true,
}

WorkRelated.propTypes = {
  allWork: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  slugToExclude: PropTypes.string,
  max: PropTypes.number,
}

export default WorkRelated
