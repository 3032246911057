//
// Convert an object to URL param string form
//
const urlparams = obj =>
  Object.keys(obj)
    .map(function(key) {
      return key + `=` + encodeURIComponent(obj[key])
    })
    .join(`&`)

export default urlparams
