import React from "react"
import PropTypes from "prop-types"

import Arrow from "../svg/Arrow"

import urlparams from "../utils/urlparams"

class Sharer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      facebookURL: null,
      twitterURL: null,
      instagramURL: null,
    }
  }

  componentDidMount() {
    let location = window.location.href

    this.setState({
      facebookURL:
        this.props.facebookUrl && this.props.outlink
          ? this.props.facebookUrl
          : this.buildFacebookURL(location),
      twitterURL:
        this.props.twitterUrl && this.props.outlink
          ? this.props.twitterUrl
          : this.buildTwitterURL(location),
      instagramURL:
        this.props.instagramUrl && this.props.outlink
          ? this.props.instagramUrl
          : null,
    })
  }

  buildFacebookURL(location, title) {
    let params = urlparams({
      t: `Wieden+Kennedy - ${this.props.title}`,
      u: encodeURI(location),
    })
    return `https://www.facebook.com/sharer/sharer.php?${params}`
  }

  buildTwitterURL(location, title) {
    let params = urlparams({
      text: `Wieden+Kennedy - ${this.props.title}`,
      url: encodeURI(location),
    })
    return `https://twitter.com/intent/tweet?${params}`
  }

  render() {
    return (
      <nav className="sharer container-fluid">
        <hr />
        <div className="row">
          <aside className="col-12 col-sm-auto sharer__label">
            <p className="no-margin">{this.props.label}</p>
          </aside>
          <nav className="col-12 col-12 col-sm-8">
            {this.state.facebookURL && (
              <a
                href={this.state.facebookURL}
                target="_blank"
                className="sharer__item btn__arrow"
                rel="noopener noreferrer"
              >
                <span>Facebook</span>
                <Arrow />
              </a>
            )}
            {this.state.twitterURL && (
              <a
                href={this.state.twitterURL}
                target="_blank"
                className="sharer__item btn__arrow"
                rel="noopener noreferrer"
              >
                <span>Twitter</span>
                <Arrow />
              </a>
            )}
            {this.state.instagramURL && (
              <a
                href={this.state.instagramURL}
                target="_blank"
                className="sharer__item btn__arrow"
                rel="noopener noreferrer"
              >
                <span>Instagram</span>
                <Arrow />
              </a>
            )}
          </nav>
        </div>
        <hr />
      </nav>
    )
  }
}

Sharer.defaultProps = {
  label: `Share on`,
}

Sharer.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  outlink: PropTypes.bool,
  facebookUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
}

export default Sharer
